import React from 'react';
import cls from 'classnames';

import type { TPAComponentProps } from 'wix-ui-tpa/dist/cssVars/types/types';

import styles from './Divider.scss';

interface IDividerProps extends TPAComponentProps {
  bw?: boolean;
  inset?: boolean;
  orientation?: 'horizontal' | 'vertical';
}

export function Divider(props: IDividerProps) {
  const { inset, className, bw, orientation, ...rest } = props;
  return (
    <hr
      className={cls(styles.root, className, {
        [styles.bw]: bw,
        [styles.inset]: inset,
        [styles.vertical]: orientation === 'vertical',
        [styles.horizontal]: orientation === 'horizontal',
      })}
      {...rest}
    />
  );
}

Divider.displayName = 'wui/Divider';
Divider.defaultProps = {
  orientation: 'horizontal',
};
