import { useContext, useLayoutEffect, useMemo } from 'react';
import { useEnvironment } from '@wix/yoshi-flow-editor';

import { PortalContext } from './context';

let $container: HTMLElement | undefined;

export function usePortalContainer() {
  const { isSSR } = useEnvironment();

  const { host } = useContext(PortalContext);

  const container = useMemo(() => {
    if (isSSR || $container) {
      return $container;
    }

    const id = `${host.id}__portal-container` as const;
    // TODO: remove "as any", fix @wix/native-components-infra when this property will be passed
    const className = [host.id, (host as any).scopedClassName].join(' ');
    const existing = document.getElementById(id);

    if (existing) {
      return ($container = existing);
    }

    $container = document.createElement('div');

    $container.id = id;
    $container.className = className;

    return $container;
  }, []);

  useLayoutEffect(() => {
    if (!container || document.getElementById(container.id)) {
      return;
    }

    const parent = document.getElementById('SITE_CONTAINER') || document.body;
    parent.appendChild(container);
  }, [container?.id]);

  return container;
}
