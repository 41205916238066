import React from 'react';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { useSettings, useStyles } from '@wix/tpa-settings/react';

import { BadgeList, BadgeType } from '@wix/members-badge-lib';

import type { IBadge } from 'api/badges/types';
import { useHttpClient } from 'common/hooks/useHttpClient';

import settingsParams from 'Group/settingsParams';
import { appearanceStylesParams } from 'settings/appearance';

import { MEMBER_BADGES_LIST } from './dataHook';

interface IProps {
  limit?: number;
  badges?: IBadge[];
}

export function MemberBadges(props: IProps) {
  const { limit, badges } = props;

  const settings = useSettings();
  const httpClient = useHttpClient();
  const styles = useStyles();
  const { i18n } = useTranslation();
  const showBadges = settings.get(settingsParams.showMembersBadges);

  if (!badges?.length || !showBadges) {
    return null;
  }

  return (
    <div data-hook={MEMBER_BADGES_LIST}>
      <BadgeList
        i18n={i18n}
        httpClient={httpClient}
        background
        font=""
        align="left"
        maxRows={1}
        maxBadges={limit}
        layout="name-icon"
        size="small"
        badges={badges as BadgeType[]}
        borderRadius={styles.get(appearanceStylesParams.cardBorderCornerRadius)}
      />
    </div>
  );
}

MemberBadges.displayName = 'MemberBadges';
MemberBadges.defaultProps = {
  limit: 2,
};
