import React from 'react';
import cls from 'classnames';

import { Box } from '../Box';

import styles from './Skeleton.scss';

interface ISkeletonProps extends React.ComponentProps<typeof Box> {
  bw?: boolean;
  variant?: 'text' | 'circular' | 'rounded' | 'rect';
}

export function Skeleton(props: ISkeletonProps) {
  const { bw, className, variant, ...rest } = props;

  return (
    <Box
      className={cls(styles.root, className, {
        [styles.bw]: bw,
        [styles.rounded]: variant === 'rounded',
        [styles.circular]: variant === 'circular',
        [styles.text]: variant === 'text',
        [styles.rect]: variant === 'rect',
      })}
      {...rest}
    />
  );
}

Skeleton.displayName = 'wui/Skeleton';

Skeleton.defaultProps = {
  variant: 'text',
};
