import React, { useRef } from 'react';
import type { FormikProps } from 'formik';
import { useSelector } from 'react-redux';
import { useTranslation } from '@wix/yoshi-flow-editor';

import {
  selectAreQuestionsLoading,
  selectAreQuestionsUpdating,
  selectGroupQuestions,
} from 'store/selectors';

import { Box } from 'wui/Box';
import { Show } from 'wui/Show';
import { Hide } from 'wui/Hide';
import { Drawer } from 'wui/Drawer';
import { Button } from 'wui/Button';
import { Spinner } from 'wui/Spinner';
import { DialogTitle } from 'wui/DialogTitle';
import { DialogActions } from 'wui/DialogActions';
import { DialogContent } from 'wui/DialogContent';
import { DialogContainer } from 'wui/DialogContainer';

import { useDidUpdate } from 'common/hooks';
import { useController } from 'common/context/controller';

import { MEMBERSHIP_QUESTIONS } from './dataHooks';
import { GroupQuestionsForm, IGroupQuestionsForm } from './GroupQuestionsForm';

interface IProps extends React.ComponentProps<typeof Drawer> {
  groupId: string;
}

export function GroupQuestionsDialog(props: IProps) {
  const { groupId, ...rest } = props;
  const { group$ } = useController();

  const { t } = useTranslation();

  const form = useRef<FormikProps<IGroupQuestionsForm>>(null);

  const questions = useSelector(selectGroupQuestions(groupId));
  const isUpdating = useSelector(selectAreQuestionsUpdating(groupId));
  const isLoading = useSelector(selectAreQuestionsLoading(groupId));

  React.useEffect(() => {
    group$.fetchQuestions(groupId);
  }, []);

  useDidUpdate(() => {
    if (!isUpdating) {
      props.onClose();
    }
  }, [isUpdating]);

  useDidUpdate(() => {
    if (!props.isOpen) {
      form.current?.resetForm();
    }
  }, [props.isOpen]);

  return (
    <Drawer hideCloseButton={false} anchor="right" {...rest}>
      <DialogContainer>
        <DialogTitle
          title={t('groups-web.group.settings.questions.edit')}
          subtitle={t('groups-web.group.settings.moderate.questions.caption')}
        />

        <DialogContent divider data-hook={MEMBERSHIP_QUESTIONS}>
          <Show if={!!isLoading}>
            <Box align="center">
              <Spinner />
            </Box>
          </Show>
          <Hide if={!!isLoading}>
            <GroupQuestionsForm
              ref={form}
              onSubmit={handleSubmit}
              initialValues={{ questions }}
            />
          </Hide>
        </DialogContent>

        <DialogActions>
          <Button secondary variant="basic" fullWidth onClick={props.onClose}>
            {t('groups-web.cancel')}
          </Button>
          <Button
            fullWidth
            variant="basic"
            onClick={handleSave}
            loading={isUpdating}
            disabled={isUpdating || isLoading}
          >
            {t('groups-web.save')}
          </Button>
        </DialogActions>
      </DialogContainer>
    </Drawer>
  );

  function handleSubmit(values: IGroupQuestionsForm) {
    group$.updateQuestions(groupId, values.questions);
  }

  function handleSave() {
    form.current?.submitForm();
  }
}

GroupQuestionsDialog.displayName = 'GroupQuestionsDialog';
