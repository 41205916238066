import React from 'react';
import cls from 'classnames';

import {
  Checkbox as TPACheckbox,
  CheckboxProps as TPACheckboxProps,
} from 'wix-ui-tpa/cssVars';

import styles from './Checkbox.scss';

export interface CheckboxProps
  extends Omit<TPACheckboxProps, 'onChange' | 'label' | 'error'> {
  onChange?: TPACheckboxProps['onChange'];
  label?: TPACheckboxProps['label'];
  error?: boolean | string;
}

export const Checkbox: React.FC<CheckboxProps> = ({
  label,
  className,
  error,
  errorMessage,
  onChange,
  ...rest
}) => {
  return (
    <TPACheckbox
      error={!!error}
      label={label || ''}
      onChange={onChange || noop}
      className={cls(styles.root, className)}
      errorMessage={typeof error === 'string' ? error : errorMessage}
      {...rest}
    />
  );
};

function noop() {}

Checkbox.displayName = 'wui/Checkbox';
