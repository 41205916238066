import React from 'react';

import {
  Dropdown as TPADropdown,
  DropdownOptionProps,
  DropdownProps,
} from 'wix-ui-tpa/cssVars';
import { usePortalContainer } from '../Portal';

import styles from './Select.scss';

interface IDropdownProps extends DropdownProps {}

export function Select(props: IDropdownProps) {
  const { ...rest } = props;

  const container = usePortalContainer();

  return (
    <TPADropdown
      fixed
      upgrade
      appendTo={container}
      optionsContainerClass={styles.container}
      optionsContainerZIndex={styles.zIndex as unknown as number}
      {...rest}
    />
  );
}

Select.displayName = 'wui/Select';

export type Option = DropdownOptionProps;
