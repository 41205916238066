import React from 'react';
import { useSelector } from 'react-redux';
import { useBi, useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import { groupActionClick } from '@wix/bi-logger-groups/v2';

import { selectGroupName, selectGroupStatus } from 'store/selectors';
import { useDidUpdate } from 'common/hooks';
import { useController } from 'common/context/controller';

import { Button } from 'wui/Button';
import { AlertDialog } from 'wui/AlertDialog';
import { DialogTitle } from 'wui/DialogTitle';
import { DialogContent } from 'wui/DialogContent';
import { DialogContentText } from 'wui/DialogContentText';
import { DialogActions } from 'wui/DialogActions';

interface DeleteDialogProps extends React.ComponentProps<typeof AlertDialog> {
  groupId: string;
}

export const DeleteGroupDialog: React.FC<DeleteDialogProps> = (props) => {
  const { groupId, ...rest } = props;

  const bi = useBi();
  const { t } = useTranslation();
  const { isMobile } = useEnvironment();
  const { group$ } = useController();

  const groupName = useSelector(selectGroupName(groupId));
  const status = useSelector(selectGroupStatus(groupId));

  const { loading, error } = status.remove;

  useDidUpdate(() => {
    if (!loading && !error) {
      props.onClose();
    }
  }, [loading, error]);

  return (
    <AlertDialog {...rest}>
      <DialogTitle alert title={t('groups-web.group.actions.delete.group')} />
      <DialogContent alert>
        <DialogContentText alert>
          {t('groups-web.group.actions.delete.confirm', {
            groupName,
          })}
        </DialogContentText>
      </DialogContent>
      <DialogActions alert>
        <Button
          secondary
          variant="basic"
          fullWidth={isMobile}
          onClick={props.onClose}
        >
          {t('groups-web.cancel')}
        </Button>
        <Button
          variant="basic"
          fullWidth={isMobile}
          disabled={loading}
          loading={loading}
          onClick={handleDeleteGroup}
        >
          {t('groups-web.group.actions.delete')}
        </Button>
      </DialogActions>
    </AlertDialog>
  );

  function handleDeleteGroup() {
    bi.report(
      groupActionClick({
        group_id: groupId,
        action: 'delete',
        origin: 'modal_dialog_button',
      }),
    );

    group$.remove(groupId);
  }
};

DeleteGroupDialog.displayName = 'DeleteGroupDialog';
