import React from 'react';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import cls from 'classnames';

import { ButtonGroup } from '../ButtonGroup';

import styles from './DialogActions.scss';

interface IDialogActionsProps extends React.ComponentProps<typeof ButtonGroup> {
  alert?: boolean;
  children: React.ReactNode;
}

export function DialogActions(props: IDialogActionsProps) {
  const { className, children, alert, ...rest } = props;
  const { isMobile } = useEnvironment();

  return (
    <ButtonGroup
      className={cls(styles.root, className, {
        [styles.alert]: alert,
        [styles.mobile]: isMobile,
      })}
      orientation={!alert ? 'horizontal' : isMobile ? 'vertical' : 'horizontal'}
      {...rest}
    >
      {children}
    </ButtonGroup>
  );
}

DialogActions.displayName = 'wui/DialogActions';
