import React, { useState } from 'react';
import { useTranslation } from '@wix/yoshi-flow-editor';

import { Image as ImageIcon } from '@wix/wix-ui-icons-common/on-stage';

import { Box } from 'wui/Box';
import { Hide } from 'wui/Hide';
import { Button } from 'wui/Button';
import { ButtonGroup } from 'wui/ButtonGroup';
import type { ImageFocalPoint } from 'wui/Media';

import { FocalPointSelect } from './FocalPointSelect';

import styles from './LogoEditor.scss';

interface ILogoEditorProps {
  enabled?: boolean;
  loading?: boolean;
  focalPoint: ImageFocalPoint;

  onLogoChange(file: File): void;
  onFocalPointChange(focalPoint: ImageFocalPoint): void;

  onSave?(): void;
  onCancel?(): void;
}

export function LogoEditor(props: ILogoEditorProps) {
  const $input = React.useRef<HTMLInputElement>(null);

  const { t } = useTranslation();
  const [isActive, setIsActive] = useState(false);

  if (!props.enabled) {
    return (
      <div className={styles.root}>
        <Box padding="SP4">
          <Button
            variant="basic"
            className={styles.edit}
            contentClassName={styles.label}
            onClick={handleUploadButtonClick}
            prefixIcon={<ImageIcon />}
          >
            {t('groups-web.reposition.change-photo')}
          </Button>
        </Box>
        <input
          hidden
          data-hook="image-input"
          type="file"
          multiple={false}
          ref={$input}
          onChange={handleChange}
          accept="image/*"
        />
      </div>
    );
  }

  return (
    <div className={styles.root}>
      <FocalPointSelect
        value={props.focalPoint}
        onActiveChange={setIsActive}
        onChange={props.onFocalPointChange}
      />

      <Hide if={isActive}>
        <Box padding="SP4" className={styles.actions}>
          <ButtonGroup>
            <Button secondary variant="basic" onClick={props.onCancel}>
              {t('groups-web.reposition.cancel')}
            </Button>
            <Button
              variant="basic"
              disabled={props.loading}
              loading={props.loading}
              onClick={props.onSave}
            >
              {t('groups-web.reposition.save')}
            </Button>
          </ButtonGroup>
        </Box>
      </Hide>
    </div>
  );

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    const [image] = Array.from(event.target.files as FileList);

    props.onLogoChange(image);
  }

  function handleUploadButtonClick() {
    $input.current!.click();
  }
}

LogoEditor.displayName = 'LogoEditor';
