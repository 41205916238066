import React, { useState } from 'react';
import { useEnvironment } from '@wix/yoshi-flow-editor';

import { ActionsMenuLayout } from 'wix-ui-tpa/cssVars';
import { Drawer } from '../Drawer';
import { Popover } from '../Popover';

import styles from './Menu.scss';

interface IProps {
  isOpen?: boolean;
  controlled?: boolean;

  element: React.ReactElement;
  children: React.ReactNode | React.ReactNode[];

  drawerProps?: Partial<React.ComponentProps<typeof Drawer>>;
  popoverProps?: Partial<React.ComponentProps<typeof Popover>>;

  onClose?(): void;
}

export function Menu(props: IProps) {
  const { controlled, element } = props;
  const { isMobile } = useEnvironment();

  const [isOpen, setIsOpen] = useState(false);

  const Element = controlled
    ? element
    : React.cloneElement(element, {
        ...element.props,
        onClick: handleOpen,
      });

  const children = React.Children.toArray(props.children).filter(
    (child): child is React.ReactElement => React.isValidElement(child),
  );

  const Content = controlled
    ? children
    : children.map((child) =>
        React.cloneElement(child, {
          ...child.props,
          onClick: handleChildClick(child.props.onClick),
        }),
      );

  if (isMobile) {
    return (
      <>
        {Element}
        <Drawer
          bw
          anchor="bottom"
          isOpen={controlled ? props.isOpen : isOpen}
          onClose={controlled ? props.onClose! : handleClose}
          {...props.drawerProps}
        >
          <ActionsMenuLayout className={styles.menu}>
            {Content}
          </ActionsMenuLayout>
        </Drawer>
      </>
    );
  }

  return (
    <Popover
      empty
      placement="bottom-end"
      isOpen={controlled ? props.isOpen : isOpen}
      onClose={controlled ? props.onClose! : handleClose}
      content={
        <ActionsMenuLayout className={styles.menu}>{Content}</ActionsMenuLayout>
      }
      {...props.popoverProps}
    >
      {Element}
    </Popover>
  );

  function handleChildClick(onClick?: Function) {
    return function (event: React.MouseEvent<HTMLButtonElement>) {
      onClick?.(event);
      handleClose();
    };
  }

  function handleOpen() {
    setIsOpen(true);
    element.props.onClick?.();
  }

  function handleClose() {
    setIsOpen(false);
  }
}

Menu.displayName = 'wui/Menu';

export const MenuItem = ActionsMenuLayout.Item;
export const MenuDivider = ActionsMenuLayout.Divider;
