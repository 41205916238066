import React from 'react';
import cls from 'classnames';

import type {
  OverridableComponent,
  OverridableComponentProps,
} from '../OverridableComponent';
import { Box } from '../Box';

import styles from './CardContent.scss';

interface IProps extends React.ComponentProps<typeof Box> {
  disableSideGutters?: boolean;
}

// eslint-disable-next-line react/display-name
export const CardContent = React.forwardRef(
  (
    props: OverridableComponentProps<IProps, 'div'>,
    ref: React.Ref<HTMLDivElement>,
  ) => {
    const { children, className, disableSideGutters, ...rest } = props;

    return (
      <Box
        ref={ref}
        direction="vertical"
        className={cls(styles.root, className)}
        padding={disableSideGutters ? undefined : 'SP0 SP5'}
        {...rest}
      >
        {children}
      </Box>
    );
  },
) as OverridableComponent<IProps, 'div'>;

CardContent.displayName = 'wui/CardContent';
