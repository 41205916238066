import React from 'react';
import cls from 'classnames';
import {
  Tooltip as TooltipTPA,
  TooltipProps,
  TooltipSkin,
} from 'wix-ui-tpa/cssVars';

import { Typography } from '../Typography';
import { usePortalContainer } from '../Portal';

import styles from './Tooltip.scss';

export interface IProps extends TooltipProps {}

export function Tooltip(props: IProps) {
  const { className, content, ...rest } = props;

  const container = usePortalContainer();

  if (!content) {
    return <>{props.children ?? null}</>;
  }

  return (
    <TooltipTPA
      fixed
      showArrow
      appendTo={container}
      skin={TooltipSkin.Wired}
      disableClickOutsideWhenClosed
      className={cls(styles.root, className)}
      zIndex={styles.zIndex as unknown as number}
      content={
        <Typography variant="p2-12" as="div">
          {content}
        </Typography>
      }
      {...rest}
    />
  );
}

Tooltip.displayName = 'wui/Tooltip';
Tooltip.defaultProps = {};
