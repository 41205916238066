import React from 'react';
import cls from 'classnames';

import type { TPAComponentProps } from 'wix-ui-tpa/dist/cssVars/types/types';

import styles from './DialogContent.scss';

interface IDialogContentProps extends TPAComponentProps {
  scrollable?: boolean;
  alert?: boolean;
  divider?: boolean;
  disableSideGutters?: boolean;
  children?: React.ReactNode;
}

export function DialogContent({
  alert,
  className,
  scrollable,
  divider,
  children,
  disableSideGutters,
  ...restProps
}: IDialogContentProps) {
  return (
    <div
      className={cls(styles.root, className, {
        [styles.alert]: alert,
        [styles.divider]: divider,
        [styles.scrollable]: scrollable,
        [styles.disableSideGutters]: disableSideGutters,
      })}
      {...restProps}
    >
      {children}
    </div>
  );
}

DialogContent.displayName = 'wui/DialogContent';
DialogContent.defaultProps = {
  scrollable: true,
};
