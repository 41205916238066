import React from 'react';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { useSelector } from 'react-redux';
import { useBi, useErrorMonitor, useTranslation } from '@wix/yoshi-flow-editor';
import { groupSettingsSave } from '@wix/bi-logger-groups/v2';

import {
  selectAllApplications,
  selectAreApplicationsUpdating,
} from 'store/selectors';
import { GroupApp, GroupAppKey } from 'store/groups/types';

import { useDidUpdate } from 'common/hooks';
import { useController } from 'common/context/controller';

import { Box } from 'wui/Box';
import { Button } from 'wui/Button';
import { Typography } from 'wui/Typography';
import { ToggleSwitch } from 'wui/ToggleSwitch';
import { DialogContent } from 'wui/DialogContent';
import { DialogActions } from 'wui/DialogActions';
import { TextField } from 'wui/TextField';
import { Stack } from 'wui/Stack';

interface IProps {
  groupId: string;

  onClose(): void;
}

export function ApplicationsTab(props: IProps) {
  const errorMonitor = useErrorMonitor();
  const { t } = useTranslation();
  const { group$ } = useController();
  const bi = useBi();

  const isUpdating = useSelector(selectAreApplicationsUpdating(props.groupId));
  const applications = useSelector(selectAllApplications(props.groupId));

  const form = useFormik({
    onSubmit: handleSubmit,
    initialValues: {
      applications: applications.map((application) => ({
        ...application,
        customName: getTabName(application),
      })),
    },
    validationSchema: yup.object().shape({
      applications: yup.array().of(
        yup.object().shape({
          customName: yup
            .string()
            .trim()
            .required(t('groups-web.group.settings.tabs.name.required.error')),
        }),
      ),
    }),
  });

  useDidUpdate(() => {
    if (!isUpdating) {
      form.setSubmitting(false);
      props.onClose();
      form.resetForm();
    }
  }, [isUpdating]);

  return (
    <>
      <DialogContent divider>
        <Box direction="vertical" gap="SP5">
          <Typography secondary>
            {t('groups-web.group.settings.tabs.caption')}
          </Typography>

          <Stack gap="SP0" direction="vertical">
            {applications.map((application, index) => (
              <Box key={application.key} gap="SP4" verticalAlign="middle">
                <Box width="100%" direction="vertical">
                  <TextField
                    showCharCount
                    maxLength={20}
                    theme="line"
                    disabled={!form.values.applications[index].installed}
                    {...form.getFieldProps(`applications.${index}.customName`)}
                    {...form.getFieldMeta(`applications.${index}.customName`)}
                  />
                </Box>

                <ToggleSwitch
                  {...form.getFieldProps({
                    type: 'checkbox',
                    name: `applications.${index}.installed`,
                  })}
                  disabled={[
                    GroupAppKey.FEED_APP,
                    GroupAppKey.ABOUT_APP,
                  ].includes(application.key as GroupAppKey)}
                />
              </Box>
            ))}
          </Stack>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button secondary variant="basic" fullWidth onClick={props.onClose}>
          {t('groups-web.cancel')}
        </Button>
        <Button
          fullWidth
          variant="basic"
          onClick={form.submitForm}
          loading={form.isSubmitting}
          disabled={form.isSubmitting || !form.isValid}
        >
          {t('groups-web.save')}
        </Button>
      </DialogActions>
    </>
  );

  function handleSubmit(values: { applications: GroupApp[] }) {
    form.setSubmitting(true);
    group$.configureApps(props.groupId, values.applications);
    bi.report(formatBi(props.groupId, applications));
  }

  function getTabName(groupApp: GroupApp): string {
    if (typeof groupApp.customName === 'undefined') {
      switch (groupApp.key) {
        case GroupAppKey.FEED_APP:
          return t('groups-web.group.settings.tabs.discussion');
        case GroupAppKey.GALLERY_APP:
          return t('groups-web.group.settings.tabs.media');
        case GroupAppKey.MEMBERS_APP:
          return t('groups-web.group.settings.tabs.members');
        case GroupAppKey.ABOUT_APP:
          return t('groups-web.group.settings.tabs.about');
        case GroupAppKey.EVENTS_APP:
          return t('groups-web.group.settings.tabs.events');
        case GroupAppKey.FILES_APP:
          return t('groups-web.group.settings.tabs.files');
        case GroupAppKey.CUSTOM_APP:
        case GroupAppKey.CUSTOM_APP_2:
        case GroupAppKey.CUSTOM_APP_3:
          return t('groups-web.group.settings.tabs.custom');
        default: {
          errorMonitor.captureMessage(
            'function getTabName:: got to default section. Unknown GroupAppKey have to be handled and translated',
            {
              contexts: {
                problemDetails: {
                  groupAppKey: groupApp.key,
                  groupApp,
                },
              },
              tags: {
                groupAppKey: groupApp.key,
              },
            },
          );
          return t('groups-web.group.settings.tabs.custom');
        }
      }
    }

    return groupApp.customName as string;
  }
}

ApplicationsTab.displayName = 'ApplicationsTab';

function formatBi(groupId: string, applications: GroupApp[]) {
  return groupSettingsSave({
    group_id: groupId,
    origin: 'save_button',
    tabName: 'tabs',
    show_media: getApplicationByKey(GroupAppKey.GALLERY_APP).installed!,
    show_members: getApplicationByKey(GroupAppKey.MEMBERS_APP).installed!,
    edit_tab: JSON.stringify({
      discussion: getApplicationByKey(GroupAppKey.FEED_APP).customName,
      media: getApplicationByKey(GroupAppKey.GALLERY_APP).customName,
      members: getApplicationByKey(GroupAppKey.MEMBERS_APP).customName,
      about: getApplicationByKey(GroupAppKey.ABOUT_APP).customName,
    }),
  });

  function getApplicationByKey(key: GroupAppKey) {
    return applications.find((app) => app.key === key) || {};
  }
}
