import React from 'react';

import type { TPAComponentProps } from 'wix-ui-tpa/dist/cssVars/types/types';

import styles from './ListItemAvatar.scss';

interface IListItemAvatarProps extends TPAComponentProps {
  children: React.ReactNode;
}

export function ListItemAvatar(props: IListItemAvatarProps) {
  return <div className={styles.root}>{props.children}</div>;
}

ListItemAvatar.displayName = 'wui/ListItemAvatar';
