import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import cls from 'classnames';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import { useSettings, useStyles } from '@wix/tpa-settings/react';

import {
  selectGroup,
  selectHasAdminRole,
  selectIsGroupUpdating,
} from 'store/selectors';

import { ImageFocalPoint, Media } from 'wui/Media';
import type { TPAComponentProps } from 'wix-ui-tpa/dist/cssVars/types/types';

import { useFileUploader } from 'common/hooks';
import { useController } from 'common/context/controller';

import { CoverImageLayout } from 'Group/Settings/settingsConstants';
import settingsParams from 'Group/settingsParams';
import stylesParams from 'Group/stylesParams';

import { LogoEditor } from './LogoEditor';

import classes from './CoverImage.scss';

interface ICoverImageProps extends TPAComponentProps {
  groupId: string;
}

export function CoverImage(props: ICoverImageProps) {
  const { isMobile, isSSR } = useEnvironment();
  const { t } = useTranslation();
  const settings = useSettings();
  const styles = useStyles();
  const isAdmin = useSelector(selectHasAdminRole(props.groupId));
  const group = useSelector(selectGroup(props.groupId));
  const isUpdating = useSelector(selectIsGroupUpdating(props.groupId));
  const { group$ } = useController();
  const fileUploader = useFileUploader();

  const { coverImage } = group;
  const coverImageLayout = settings.get(settingsParams.coverImageLayout);
  const coverImageHeight = styles.get(stylesParams.coverImageHeight);
  const isLarge = coverImageLayout === CoverImageLayout.large;
  const isThumbnail = coverImageLayout === CoverImageLayout.thumbnail;

  const targetWidth = useMemo(
    () => (isMobile ? 320 : isLarge ? 940 : 100),
    [isMobile, isLarge],
  );

  const targetHeight = useMemo(() => {
    if (isMobile) {
      return 240;
    }

    return isLarge ? coverImageHeight || 240 : 100;
  }, [isMobile, isLarge, coverImageHeight]);

  const [logo, setLogo] = useState<File>();
  const [isLoading, setIsLoading] = useState(false);
  const [imageSrc, setImageSrc] = useState(getImageSrc());
  const [focalPoint, setFocalPoint] = useState(getFocalPoint());

  useEffect(() => {
    setImageSrc(getImageSrc());
  }, [coverImage?.image?.mediaId, coverImage?.image?.fileUrl]);

  useEffect(() => {
    setFocalPoint(getFocalPoint());
  }, [
    isMobile,
    JSON.stringify(coverImage?.position),
    JSON.stringify(coverImage?.mobilePosition),
  ]);

  return (
    <div
      data-hook="super-hero-image-root"
      className={cls(classes.root, props.className, {
        [classes.mobile]: isMobile,
        [classes.thumbnail]: isThumbnail,
        [classes.large]: isLarge,
      })}
    >
      <Media
        isInFirstFold
        key={imageSrc}
        fluid={isLarge}
        src={imageSrc}
        width={targetWidth}
        height={targetHeight}
        data-hook="group-image"
        focalPoint={focalPoint}
        resize="cover"
        loadingBehavior="blur"
        sourceWidth={group.coverImage?.image?.width as number}
        sourceHeight={group.coverImage?.image?.height as number}
      />
      {isAdmin && isLarge && !isMobile && (
        <LogoEditor
          loading={isLoading || isUpdating}
          focalPoint={focalPoint}
          onLogoChange={handleLogoChange}
          onFocalPointChange={handleFocalPointChange}
          enabled={!!logo}
          onSave={handleSave}
          onCancel={handleCancel}
        />
      )}
    </div>
  );

  function handleCancel() {
    setLogo(undefined);
    setImageSrc(getImageSrc());
    setFocalPoint(getFocalPoint());
  }

  async function handleSave() {
    if (!logo) {
      return;
    }

    setIsLoading(true);

    const {
      height,
      width,
      file_name: mediaId,
    } = await fileUploader.upload(logo);

    setIsLoading(false);

    group$.updateGroupInfo(props.groupId, {
      coverImage: {
        image: {
          mediaId,
          height,
          width,
        },
        position: focalPoint,
      },
    });

    setLogo(undefined);
  }

  function handleFocalPointChange(focalPoint: ImageFocalPoint) {
    setFocalPoint(focalPoint);
  }

  function handleLogoChange(file: File) {
    imageSrc && URL.revokeObjectURL(imageSrc);
    setImageSrc(URL.createObjectURL(file));
    setFocalPoint({ x: 0, y: 50 });
    setLogo(file);
  }

  function getImageSrc() {
    return (coverImage?.image?.mediaId || coverImage?.image?.fileUrl) as string;
  }

  function getFocalPoint() {
    const focalPoint = isMobile
      ? coverImage?.mobilePosition
      : coverImage?.position;

    return (focalPoint as ImageFocalPoint) || { x: 50, y: 50 };
  }
}

CoverImage.displayName = 'CoverImage';
