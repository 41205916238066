import { useEffect, useRef } from 'react';
import { useEnvironment } from '@wix/yoshi-flow-editor';

import styles from './ScrollBlock.scss';

export function useScrollBlock(blocked?: boolean) {
  const { isSSR } = useEnvironment();

  const eligible = useRef(false);

  useEffect(() => {
    if (isSSR) {
      return;
    }

    blocked ? lock() : unlock();

    return () => unlock();
  }, [isSSR, blocked]);

  function lock() {
    const isLocked = document.body.classList.contains(styles.lock);

    eligible.current = !isLocked;

    if (!isLocked) {
      document.body.classList.add(styles.lock);
    }
  }

  function unlock() {
    if (eligible.current) {
      document.body.classList.remove(styles.lock);
    }
  }
}
